import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide1/dataPageGuide1"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        14 plantillas de mensajes para vender por WhatsApp Business API
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/plantillas-mensajes-vender-whatsapp/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="¿Buscas plantillas de WhatsApp Business API para tus ventas? Aquí encontrarás 14 ejemplos que te ayudarán a inspirarte en tu negocio."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="14 plantillas de mensajes para vender por WhatsApp Business API"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/plantillas-mensajes-vender-whatsapp/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia1.jpg`}
      />
      <meta
        property="og:description"
        content="¿Buscas plantillas de WhatsApp Business API para tus ventas? Aquí encontrarás 14 ejemplos que te ayudarán a inspirarte en tu negocio."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="WhatsApp"
      location={location}
      typeGuide="guide1"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
